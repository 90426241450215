import { useState, useEffect } from 'react';

const AUTOFILL_DELAY = 500;
const AUTOFILL_BG_COLOR = 'rgb(255, 255, 255)';

const useAutofillCheck = inputRefs => {
  const [isAutofillData, setIsAutofillData] = useState(false);

  useEffect(() => {
    const checkAutofill = () => {
      const allAutofilled = inputRefs.every(ref => {
        const element = ref.current;
        if (element) {
          const isAutofill = !window
            .getComputedStyle(element)
            .backgroundColor.includes(AUTOFILL_BG_COLOR);
          return isAutofill;
        }
        return false;
      });
      setIsAutofillData(allAutofilled);
    };

    const timeoutId = setInterval(checkAutofill, AUTOFILL_DELAY);

    return () => clearInterval(timeoutId);
  }, [inputRefs]);

  return isAutofillData;
};

export default useAutofillCheck;
