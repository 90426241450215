import { Field, Form, Formik } from 'formik';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useRef, useState } from 'react';

import useAutofillCheck from 'hooks/useAutofillCheck';

import { LoginFormInitialValues as initialValues } from 'utils/constants/formsInitialValues';
import { LoginValidationSchema as validationSchema } from 'utils/functions/validationSchemas';

type LoginFormProps = {
  onSubmit: (values: typeof initialValues) => void;
};

const LoginForm = ({ onSubmit }: LoginFormProps) => {
  const [showPassword, setShowPasssword] = useState(false);
  const { query: queryParams } = useRouter();

  const inputEmailRef = useRef<HTMLInputElement>(null);
  const inputPasswordRef = useRef<HTMLInputElement>(null);

  const isAutofillData = useAutofillCheck([inputEmailRef, inputPasswordRef]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnMount
    >
      {({ isValid }) => (
        <Form className="flex flex-col mx-20">
          <div className="border-b border-gray-200 dark:border-gray-700 mb-5">
            <ul className="flex flex-wrap text-sm font-medium text-center text-gray-500 dark:text-gray-400">
              <li className="me-2 flex-auto">
                <Link
                  href="/login"
                  className="w-full inline-flex items-center justify-center p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500 group"
                >
                  Sign In
                </Link>
              </li>
              <li className="me-2 flex-auto">
                <Link
                  href="/register"
                  className="w-full inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group"
                >
                  Join Waitlist
                </Link>
              </li>
            </ul>
          </div>
          <label
            htmlFor="email"
            className="aeonik mb-5 text-xl font-aeonik-medium"
          >
            Sign in to your account
          </label>
          <div className="flex flex-col mb-6 gap-3">
            {queryParams?.error === 'CredentialsSignin' && (
              <>
                <div
                  className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
                  role="alert"
                >
                  invalid username or password
                </div>
              </>
            )}

            <div className="flex flex-col gap-1">
              <label className="aeonik text-md text-gray-500">Your email</label>
              <Field
                innerRef={inputEmailRef}
                name="email"
                type="email"
                placeholder="name@email.com"
                className={`border w-full h-12 text-sm focus:outline-none mr-1 rounded-lg bg-surface text-neutrals-80 placeholder:text-neutrals-60 border-neutrals-40`}
              />
            </div>
            <div className="relative flex flex-col gap-1">
              <label className="aeonik text-md text-gray-500">Password</label>
              <div className="relative w-full">
                <Field
                  innerRef={inputPasswordRef}
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  placeholder={showPassword ? 'password' : '••••••••'}
                  className={`border w-full h-12 text-sm focus:outline-none mr-1 rounded-lg bg-surface text-neutrals-80 placeholder:text-neutrals-60 border-neutrals-40`}
                />
                <label
                  className="absolute top-0 bottom-0 m-auto right-3 h-6 cursor-pointer"
                  onClick={() => {
                    setShowPasssword(prevState => !prevState);
                  }}
                >
                  Show
                </label>
              </div>
              <Link
                className="aeonik text-m text-primary-60 mt-1"
                href="/forgot-password"
              >
                Forgot password ?
              </Link>
            </div>
          </div>
          <button
            type="submit"
            disabled={!isValid}
            className={`border h-12 text-sm focus:outline-none mr-1 rounded-full ${
              isValid || isAutofillData
                ? 'bg-primary-60 text-white'
                : 'bg-neutrals-40 text-neutrals-100'
            }`}
          >
            Connect to Endless
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
